<template>
  <div class="editor-wrapper">
    <textarea
      v-model="content"
      :placeholder="placeholder || $t('input.text')"
      @change="change"
    />
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  computed: {
    content: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    change () {
      this.$emit('change', this.value)
    }
  }
}
</script>

<style lang="stylus" scoped>
textarea
  padding 16px
  width 100%
  min-height 200px
</style>
